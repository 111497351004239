export const photos_green = [
  {
    src: "./greenImagesbox/BigTree.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/orbitflowers.jpg",
    width: 4,
    height: 3,
  },

  {
    src: "./greenImagesbox/WatchingBlueSky.jpg",
    width: 4,
    height: 3,
  },

  {
    src: "./greenImagesbox/Autumn21_1.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "./greenImagesbox/greenpark.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/foglioline.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/nebbia2014.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/Autunno2013.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/Paradiso.jpg",
    width: 4,
    height: 3,
  },

  {
    src: "./greenImagesbox/FoglieDautunno.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./greenImagesbox/wp2.jpg",
    width: 4,
    height: 3,
  },
];
