export const photos_people = [
  {
    src: "./peopleImagesbox/Food4ThoughtsPrint.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./peopleImagesbox/fruttaw.jpg",
    width: 5,
    height: 3,
  },
  {
    src: "./peopleImagesbox/Hallowen2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./peopleImagesbox/JamesStPaul.jpg",
    width: 5,
    height: 3,
  },
  {
    src: "./peopleImagesbox/misteryw.jpg",
    width: 5,
    height: 3,
  },
  {
    src: "./peopleImagesbox/Drums&Tobacco2014.jpg",
    width: 5,
    height: 3,
  },

  {
    src: "./peopleImagesbox/thewalk17.jpg",
    width: 5,
    height: 3,
  },
  {
    src: "./peopleImagesbox/vetrina.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./peopleImagesbox/workingman.jpg",
    width: 5,
    height: 3,
  },

  {
    src: "./peopleImagesbox/Deniz_Peluche2.jpg",
    width: 4,
    height: 3,
  },

  {
    src: "./peopleImagesbox/AsTimeGoesBy.jpg",
    width: 3,
    height: 5,
  },
];
